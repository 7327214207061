import * as React from "react"
import { CloseButton } from "../components/Modal/CloseButton"
import { Modal } from "../components/Modal/Modal"
import { Seo } from "../components/Seo"
import { SubpageLayout } from "../components/SubpageLayout"
import { SideElements } from "../components/SideElements/SideElements"
import styled from "styled-components"

const Link = styled.a`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
const Icon = styled.i`
  padding-right: .4rem;
`

const FullScreenYoutubeIFrame = (
  { id, message, src }: { id: string, message: React.ReactNode, src: string }): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <div
      key={id}
      className="pb-3"
    >
      <Link
        onClick={() => setIsOpen(!isOpen)}
      >
        <Icon className="fa fa-play-circle" />
        {message}
      </Link>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
      >
        <CloseButton onClick={() => setIsOpen(false)} />
        <iframe
          allowFullScreen
          src={src}
          className="video-testimonial-content"
          allow="autoplay"
        />
      </Modal>
    </div>
  )
}

const shortAppPresentation = [{
  id: "1",
  message: (
    <>
      Poznaj&nbsp;
      <span
        style={{ color: "#d8232a" }}
        className="font-bold"
      >
        FAKTURUJ.
      </span>
      <span
        style={{ color: "#283278" }}
        className="font-bold"
      >
        TO
      </span>
    </>
  ),
  src: "https://www.youtube.com/embed/watch?v=2sdxtkKEGRI&list=PLVDABQPBZgwR7JGf39o34_6kObmG8AHRy&index=1&autoplay=1",
},
]

const majorFunctions = [
  {
    id: "1",
    message: "Wystawienie faktury sprzedaży",
    src: "https://www.youtube.com/embed/watch?v=2dZDJewLYAg&list=PLVDABQPBZgwR7JGf39o34_6kObmG8AHRy&index=2&autoplay=1",
  },
  {
    id: "2",
    message: "Korekta",
    src: "https://www.youtube.com/embed/watch?v=2dZDJewLYAg&list=PLVDABQPBZgwR7JGf39o34_6kObmG8AHRy&index=3&autoplay=1",
  },
  {
    id: "3",
    message: "Zaliczki",
    src: "https://www.youtube.com/embed/watch?v=9DVbkyyTc0A&list=PLVDABQPBZgwR7JGf39o34_6kObmG8AHRy&index=4&autoplay=1",
  },
  {
    id: "4",
    message: "Drukowanie faktury w języku angielskim",
    src: "https://www.youtube.com/embed/watch?v=_Q7LKBwpdhE&list=PLVDABQPBZgwR7JGf39o34_6kObmG8AHRy&index=5&autoplay=1",
  },
  {
    id: "5",
    message: "Różne typy faktur",
    src: "https://www.youtube.com/embed/watch?v=ofy5kzViN2w&list=PLVDABQPBZgwR7JGf39o34_6kObmG8AHRy&index=6&autoplay=1",
  },
  {
    id: "6",
    message: "Anulowanie faktury",
    src: "https://www.youtube.com/embed/watch?v=CAnq00UISKQ&list=PLVDABQPBZgwR7JGf39o34_6kObmG8AHRy&index=7&autoplay=1",
  },
  {
    id: "7",
    message: "Drzewo wydarzeń",
    src: "https://www.youtube.com/embed/watch?v=ZNk6KLdpXoo&list=PLVDABQPBZgwR7JGf39o34_6kObmG8AHRy&index=8&autoplay=1",
  },
  {
    id: "8",
    message: "Kategorie",
    src: "https://www.youtube.com/embed/watch?v=mCKc-7yIJTU&list=PLVDABQPBZgwR7JGf39o34_6kObmG8AHRy&index=9&autoplay=1",
  },
  {
    id: "9",
    message: "Dodawanie faktury zakupowej z pulpitu",
    src: "https://www.youtube.com/embed/watch?v=leFS9-id888&list=PLVDABQPBZgwR7JGf39o34_6kObmG8AHRy&index=10&autoplay=1",
  },
  {
    id: "10",
    message: "Dodawanie faktury zakupowej z listy faktur",
    src: "https://www.youtube.com/embed/watch?v=leFS9-id888&list=PLVDABQPBZgwR7JGf39o34_6kObmG8AHRy&index=11&autoplay=1",
  },
  {
    id: "11",
    message: "Edycja faktury zakupowej",
    src: "https://www.youtube.com/embed/watch?v=yShJ8cum3Ek&list=PLVDABQPBZgwR7JGf39o34_6kObmG8AHRy&index=12&autoplay=1",
  },
  {
    id: "12",
    message: "Filtrowanie po tagach listy faktur zakupowych",
    src: "https://www.youtube.com/embed/watch?v=leFS9-id888&list=PLVDABQPBZgwR7JGf39o34_6kObmG8AHRy&index=13&autoplay=1",
  },
  {
    id: "13",
    message: "Produkty",
    src: "https://www.youtube.com/embed/watch?v=hgtXzF2BHBc&list=PLVDABQPBZgwR7JGf39o34_6kObmG8AHRy&index=14&autoplay=1",
  },
  {
    id: "14",
    message: "CRM",
    src: "https://www.youtube.com/embed/watch?v=hgtXzF2BHBc&list=PLVDABQPBZgwR7JGf39o34_6kObmG8AHRy&index=15&autoplay=1",
  },
  {
    id: "15",
    message: "Ustawienia Twojej Firmy",
    src: "https://www.youtube.com/embed/watch?v=FSzxkP2imjs&list=PLVDABQPBZgwR7JGf39o34_6kObmG8AHRy&index=16&autoplay=1",
  },
  {
    id: "16",
    message: "Przełączanie między firmami",
    src: "https://www.youtube.com/embed/watch?v=YfkMyWm3H0M&list=PLVDABQPBZgwR7JGf39o34_6kObmG8AHRy&index=17&autoplay=1",
  },
]

const accountancy = [{
  id: "1",
  message: "Księgowość",
  src: "https://www.youtube.com/embed/watch?v=T4l6_DxJzI0&list=PLVDABQPBZgwSmo7LQEc9BXNqUVdrIPN3G&index=1&autoplay=1",
}]

const accountingOffice = [
  {
    id: "1",
    message: "Konwerter wyciągów bankowych",
    src: "https://www.youtube.com/embed/watch?v=HhidcZ4BGPo&list=PLVDABQPBZgwRcDxehUESiY5yoEhAWzYUA&index=1&autoplay=1",
  },
  {
    id: "2",
    message: "Automatyzacje",
    src: "https://www.youtube.com/embed/watch?v=_MSLFYAVsVU&list=PLVDABQPBZgwRcDxehUESiY5yoEhAWzYUA&index=2&autoplay=1",
  },
]

const tipsAndTricks = [
  {
    id: "1",
    message: "Faktura zewnętrzna",
    src: "https://www.youtube.com/embed/watch?v=0f-oXMuea6A&list=PLVDABQPBZgwRRtKfdWlXyroQA5a8WbNPP&index=1&autoplay=1",
  },
  {
    id: "2",
    message: "Tagi",
    src: "https://www.youtube.com/embed/watch?v=0f-oXMuea6A&list=PLVDABQPBZgwRRtKfdWlXyroQA5a8WbNPP&index=3&autoplay=1",
  },
  {
    id: "3",
    message: "Faktówka",
    src: "https://www.youtube.com/embed/watch?v=0f-oXMuea6A&list=PLVDABQPBZgwRRtKfdWlXyroQA5a8WbNPP&index=2&autoplay=1",
  },
]

const IntroductionFilms = () => (
  <SubpageLayout
    title="Filmy wprowadzające"
  >
    <Seo
      title="Filmy wprowadzające"
    />
    <SideElements />
    <div>
      <div
        className="pl-5 content-color clearance-after"
      >
        {shortAppPresentation.map(item => (
          <FullScreenYoutubeIFrame {...item} />
        ))}
      </div>
      <section>
        <h2
          className="uppercase text-3xl mb-4"
        >
          Sekcja 1: Podstawowe funkcje aplikacji
        </h2>
        <div className="pl-5 content-color clearance-after">
          {majorFunctions.map(item => (
            <FullScreenYoutubeIFrame {...item} />
          ))}
        </div>
      </section>
      <section>
        <h2
          className="uppercase text-3xl mb-4"
        >
          Sekcja 2: Księgowość
        </h2>
        <div className="pl-5 content-color clearance-after">
          {accountancy.map(item => (
            <FullScreenYoutubeIFrame {...item} />
          ))}
        </div>
      </section>
      <section>
        <h2
          className="uppercase text-3xl mb-4 "
        >
          Sekcja 3: Biuro księgowe
        </h2>
        <div className="pl-5 content-color clearance-after">
          {accountingOffice.map(item => (
            <FullScreenYoutubeIFrame {...item} />
          ))}
        </div>
      </section>
      <section>
        <h2
          className="uppercase text-3xl mb-4 "
        >
          Sekcja 4: Triki i sztuczki
        </h2>
        <div className="pl-5 content-color clearance-after">
          {tipsAndTricks.map(item => (
            <FullScreenYoutubeIFrame {...item} />
          ))}
        </div>
      </section>
    </div>
  </SubpageLayout>
)

export default IntroductionFilms
